import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { VendorStateInterface } from "./state";
import { VendorResponse } from "@/types/Vendor/Response/VendorResponse";
import { VendorAccountResponse } from "@/types/Vendor/Response/VendorAccountResponse";
import { CategoryResponse } from "@/types/Vendor/Response/CategoryResponse";

export const getters: GetterTree<VendorStateInterface, RootState> = {
	getVendor(state): VendorResponse {
		return state.vendor;
	},
	getVendorAccount(state): VendorAccountResponse {
		return state.vendorAccount;
	},
	getCategory(state): CategoryResponse {
		return state.categoryList[0];
	},
};