<template>
	<b-modal
		id="gameIdInfo-modal"
		class="myaccount-modal"
		size="md"
		hide-footer
		centered
		data-backdrop="static"
		:title="$t('GameIDInfoModal.GameIDInfo')"
	>
		<b-container v-if="isLogin">
			<DownloadAccount></DownloadAccount>
		</b-container>
	</b-modal>
</template>
<style>
.myaccount-modal label {
	color: #000000;
}
.myaccount-modal .gameAccountContainer {
	padding-right: 0px !important;
	padding-left: 0px !important;
}
</style>
<script>
import DownloadAccount from "@/components/content/Download/DownloadAccount.vue";
export default {
	data() {
		return {};
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
	},
	components: {
		DownloadAccount,
	},
	methods: {},
	watch: {},
};
</script>
