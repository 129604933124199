export default {
  PROFILE: "Profile",
  AccountID: "Account ID",
  GameID: "Game ID",
  DateOfBorth: "Date Of Borth",
  FullName: "Full Name",
  Update: "Update",
  UpdateSuccess: "Update Success",
  doBirth: "Date Of Birth",
  DOBHolderRequired: "*Date Of Birth Required",
	birthdayIsCorrect: "Please make sure that the birthday date is correct! You can only set once and cannot be changed!",
	Cancel: "Cancel",
	OK: "Yes, Im sure!",
	AreYouSure: "Are you sure?",
};
