<template>
	<b-modal
		id="login-modal"
		size="md"
		class="default-modal"
		hide-footer
		centered
		:title="$t('LoginModal.LoginWin')"
		ref="modalLogin"
		v-model="show"
		@show="clearData()"
	>
		<b-container class="px-5" v-if="loginContainer">
			<b-row>
				<b-col cols="12">
					<b-form @submit.prevent="submitForm" @keyup.enter="submitForm">
						<b-input-group class="mt-3" prepend="+60">
							<b-form-input
								v-model="loginForm.phoneNo"
								:placeholder="$t('LoginModal.PhoneNumber')"
								type="text"
								required
								inputmode="numeric"
							></b-form-input
						></b-input-group>
						<div class="input-errors" v-for="(error, index) of v$.loginForm.phoneNo.$errors" :key="index">
							<div class="error-msg">{{ error.$message }}</div>
						</div>

						<b-input-group class="mt-3">
							<b-form-input
								v-model="loginForm.password"
								:placeholder="$t('LoginModal.Password')"
								:type="passwordFieldType"
								required
							></b-form-input>
							<b-input-group-append>
								<b-button squared class="text-dark show-hide" @click="changePasswordType">
									{{ $t("LoginModal." + this.showHideText) }}
								</b-button>
							</b-input-group-append>
						</b-input-group>
						<div class="input-errors" v-for="(error, index) of v$.loginForm.password.$errors" :key="index">
							<div class="error-msg">{{ error.$message }}</div>
						</div>
					</b-form>
				</b-col>
			</b-row>
			<b-row class="mx-0 my-3">
				<b-button variant="modal" block @click="submitForm()">{{ $t("LoginModal.Login") }}</b-button>
				<a href="javascript:void(0)" v-b-modal.forgotPassword-modal>
					{{ $t("LoginModal.ForgotYourPassword") }}
				</a>
			</b-row>
		</b-container>
		<b-container class="px-5" v-if="tacContainer">
			<b-row class="verifieContent">
				<b-col>
					<b-form>
						<b-col class="mt-3">
							<h5 class="verifieType">{{ $t("LoginModal.VerificationNumber") }}</h5>
						</b-col>
						<b-col class="mt-3 px-3">
							<b-input-group>
								<b-form-input
									class="text-center"
									v-model="tacCode"
									:placeholder="$t('LoginModal.TACCode')"
									type="number"
									required
								></b-form-input>
								<b-input-group-append>
									<b-button variant="main" squared block @click="requestTAC">{{ $t("LoginModal.Resend") }}</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-form>
				</b-col>
			</b-row>
			<b-row class="mx-0 mb-3 mt-5">
				<b-button variant="main" block @click="submitTAC">{{ $t("LoginModal.ConfirmTAC") }}</b-button>
			</b-row>
		</b-container>
		<b-container class="footer">
			{{ $t("LoginModal.NotAMemberYet") }}
			<a href="javascript:void(0)" v-b-modal.signup-modal>{{ $t("LoginModal.SignUp") }}</a>
		</b-container>
	</b-modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, numeric, maxLength } from "@vuelidate/validators";
export default {
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			loginForm: {
				phoneNo: {
					required: helpers.withMessage(() => this.$t("LoginModal.PhoneRequired"), required),
					numeric: helpers.withMessage(() => this.$t("LoginModal.PhoneNumeric"), numeric),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("LoginModal.PhoneMin", { minLength: $params.min }),
						minLength(9)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("LoginModal.PhoneMax", { maxLength: $params.max }),
						maxLength(10)
					),
				},
				password: {
					required: helpers.withMessage(() => this.$t("LoginModal.PasswordRequired"), required),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("LoginModal.PasswordMin", { minLength: $params.min }),
						minLength(6)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("LoginModal.PasswordMax", { maxLength: $params.max }),
						maxLength(16)
					),
				},
			},
		};
	},
	data() {
		return {
			loginForm: {
				phoneNo: "",
				password: "",
			},
			phoneMin: 9,
			phoneMax: 10,
			passwordMin: 6,
			passwordMax: 16,
			passwordFieldType: "password",
			showHideText: "Show",
			show: false,
			loginContainer: true,
			tacContainer: false,
			tacCode: null,
		};
	},
	computed: {},
	components: {},
	methods: {
		changePasswordType() {
			this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
			this.showHideText = this.showHideText == "Show" ? "Hide" : "Show";
		},
		async submitForm() {
			const result = await this.v$.$validate();
			if (!result) {
				return;
			}
			let loader = this.$loading.show({
				container: this.fullPage ? null : this.$refs.formContainer,
			});
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			await this.$store
				.dispatch("identityServer/fetchLoginToken", this.loginForm)
				.then((res) => {
					if (res.loginStatus) {
						loader.hide();
						window.scrollTo(0, 0);
						this.fetchWinover();
						this.toast.success(this.$t("LoginModal.LoginSuccess"), { position: toastPosition, timeout: 2000 });
						this.show = false;
					} else if (!res.loginStatus && res.isPhoneConfirmError) {
						loader.hide();
						this.requestTAC();
						this.tacContainer = true;
						this.loginContainer = false;
					} else {
						let toastMsg = "LoginModal.LoginError";
						loader.hide();
						this.toast.error(this.$t(toastMsg), { position: toastPosition });
					}
				})
				.catch((err) => {
					let toastMsg = "LoginModal.LoginError";
					if (err.message == "GameError") {
						toastMsg = "LoginModal.GameError";
					}
					loader.hide();
					this.toast.error(this.$t(toastMsg), { position: toastPosition });
				});
		},
		async requestTAC() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			await this.$store
				.dispatch("register/requestTAC", this.loginForm)
				.then(() => {
					this.toast.success(this.$t("LoginModal.RequestTACSuccess"), { position: toastPosition });
				})
				.catch(() => {
					this.toast.error(this.$t("LoginModal.RequestTACFailed"), { position: toastPosition });
				});
		},
		async submitTAC() {
			let loader = this.$loading.show({
				container: this.fullPage ? null : this.$refs.formContainer,
			});
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			await this.$store
				.dispatch("register/verifyTAC", { phoneNo: this.loginForm.phoneNo, token: this.tacCode })
				.then(() => {
					loader.hide();
					this.submitForm();
				})
				.catch(() => {
					loader.hide();
					this.toast.error(this.$t("LoginModal.VerifyTACError"), { position: toastPosition });
				});
		},
		clearData() {
			this.loginForm.phoneNo = "";
			this.loginForm.password = "";
			this.loginContainer = true;
			this.tacContainer = false;
			this.$nextTick(() => {
				this.v$.$reset();
			});
		},
		async fetchWinover() {
			const loginId = this.$store.getters["identityServer/getLoginId"];
			await this.$store.dispatch("memberAccount/fetchMemberWinover", { loginId: loginId, isFromDB: true });
		},
	},
};
</script>
