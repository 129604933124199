export default {
  WelcomVIPDesc: "Monthly Rank",
  Red: "Red",
  Silver: "Silver",
  Gold: "Gold",
  Platinum: "Platinum",
  Diamond: "Diamond",
  Royal: "Royal",
  Regular: "Regular",
  DepositPertama: "Deposit Pertama",
  firstDeposit: "Deposit Pertama",
  MinDeposit: "Min. Deposit",
  VIPPrivileges: "KEISTIMEWAAN VIP",
  giftTreats: "HADIAH & GANJARAN",
  tierUpgradeBonus: "Bonus Naik Taraf",
  birthdayBonus: "Bonus Hari Jadi",
  dailyRescueBonus: "Bonus Penyelamat Harian",
  weeklyRescueBonus: "Bonus Penyelamat Mingguan",
  designatedAccountManager: "Pengurus Akaun Eksklusif",
  SpecialRebate: "REBAT KHAS",
  SlotsRebate: "Slots Rebate",
  LiveCasinoRebate: "Live Kasino Rebate",
  SportsRebate: "Sports Rebate",
  tierUpgradeRequirement: "KEPERLUAN PENINGKATAN TAHAP",
  minDepositRotation: "Minimum Deposit ",
  within_DAILY: "Dalam 1 Hari ",
  within_WEEKLY: "Dalam 1 Minggu ",
  within_MONTHLY: "Dalam 1 Bulan",
  within_QUARTERLY: "Dalam 3 Bulan",
  "within_HALF YEARLY": "Dalam 6 Bulan",
  within_YEARLY: "Dalam 1 Tahun",
  within_LIFETIME: "Dalam Seumum Hidup",
  tierRetentionRequirement: "KEPERLUAN PENYELENGGARAN TAHAP",
  retentionTotalDeposit: "Deposit Terkumpul ",
  membershipRotation: "Pembaharuan Keahlian",
  Lifetime: "Lifetime",
  Quarterly: "Quarterly",
  VIPExclusive: "VIP Eksklusif",
  Yes: "Ya",
  ONCE: "Sekali",
  ALWAYS: "Sentiasa",
  DAILY: "Harian",
  WEEKLY: "Mingguan",
  MONTHLY: "Bulanan",
  QUARTERLY: "Suku",
  "HALF YEARLY": "Setengah Tahun",
  YEARLY: "Tahunan",
  LIFETIME: "Seumur Hidup",
  CurrentStatus: "Status Semasa",
  FAQs: "FAQs",
  QuestionHowToBecomeAVIPMember: "S: Bagaimana untuk menjadi ahli VIP",
  AnswerHowToBecomeAVIPMember:
    "J: Anda perlu mendepositkan sekurang-kurangnya RM{0} dalam tempoh satu suku ({1} bulan) untuk menjadi Ahli Silver.",
  QuestionHowManyLevel: "S: Berapa banyak peringkat yang ada untuk program VIP?",
  AnswerHowManyLevel:
    "J: Terdapat sejumlah 6 peringkat. Program VIP terdiri daripada peringkat Red, Silver, Gold, Platinum, Diamond dan Royal.",
  QuestionHowToUpgradeNextLevel: "S: Bagaimanakah cara untuk saya naik taraf ke peringkat seterusnya?",
  AnswerHowToUpgradeNextLevel:
    "J: Anda perlu mendepositkan jumlah minimum yang diperlukan oleh setiap peringkat ke dalam akaun anda. Sebagai contoh, jika anda kini Ahli Red, anda perlu mendepositkan sekurang-kurangnya RM{0} untuk menjadi Ahli Silver, dan daripada Ahli Silver, anda perlu mendepositkan sekurang-kurangnya RM{1} lagi untuk menjadi Ahli Gold.",
  QuestionHowlongMemberstatus: "S: Berapa lama sah status Keahlian VIP saya?",
  AnswerHowlongMemberstatus:
    "J: Sebaik sahaja anda dinaik taraf ke peringkat seterusnya, Keahlian VIP anda sah selama setahun berdasarkan tahap peringkat tertinggi yang diarkibkan.",
  QuestionHowDoIRetainMembership: "S: Bagaimanakah saya mengekalkan status keahlian VIP saya?",
  AnswerHowDoIRetainMembership:
    "J: Untuk mengekalkan status peringkat ahli anda untuk tahun berikutnya, anda perlu mendepositkan jumlah yang diperlukan pada suku terakhir sebelum tarikh tamat tempoh. Sebagai contoh, jika status Ahli Gold anda sah dari Januari - Disember, anda perlu mencapai deposit RM15,000 menjelang suku akhir tahun (Oktober) untuk mengekalkan tahap sedia ada anda untuk tahun berikutnya. Walau bagaimanapun, status keahlian peringkat Red dan Silver adalah sah seumur hidup.",
  QuestionIsThereAnnualFees:
    "S: Adakah terdapat yuran tahunan atau bulanan untuk menjadi sebahagian daripada ahli VIP?",
  AnswerIsThereAnnualFees:
    "J: Tidak. Program ini diwujudkan untuk mempromosikan semua ahli setia yang aktif menyokong pengalaman permainan kami sejak tarikh berdaftar.",
  ImportantNote: "Catatan Penting: ",
  TermsConditions: "Terma & Syarat ",
  tnc1: "Untuk naik taraf kali pertama, ahli dikehendaki menyediakan semua dokumen yang diperlukan untuk tujuan pengesahan. Selepas pengesahan selesai, status keahlian akan dikemaskini ke dalam sistem dalam tempoh 3 hari bekerja.",
  TheVerificationStepsAreAsFollows: "Langkah-langkah pengesahan adalah seperti berikut:",
  tnc1stepI: "i. Sahkan Butiran Profil (Nama penuh, Nombor Telefon dan Alamat E-mel)",
  tnc1stepII: "ii. Sahkan Aktiviti Akaun Permainan (Gaya Pertaruhan, Aktiviti Deposit &amp; Pengeluaran)",
  tnc1ImportantNote:
    "Sekiranya terdapat sebarang aktiviti yang mencurigakan atau tidak normal dalam tempoh pengesahan 3 hari, pihak pengurusan berhak menolak kelayakan untuk menaik taraf.",
  tnc2: "Keistimewaan VIP boleh dinikmati serta-merta selepas menaik taraf ke peringkat seterusnya dan sah selama tiga bulan berdasarkan peringkat tertinggi yang dicapai.",
  tnc2ImportantNote:
    "Untuk mengekalkan status keahlian anda, ahli dikehendaki mencapai keperluan yang sama dalam tempoh satu suku.",
  tnc3: "Semua jemputan naik taraf status VIP akan dihantar oleh Bahagian VIP Pussy888.",
  tnc4: "Pussy888 berhak untuk mengalih keluar keistimewaan VIP akaun ahli, dan menurunkan taraf tahap VIP atau bahkan menamatkan mana-mana akaun yang dianggap mempunyai sebarang aktiviti yang mencurigakan atau tidak normal pada bila-bila masa tanpa sebarang notis terlebih dahulu",
  tnc4ImportantNote: "Setiap status keahlian tertakluk kepada penurunan taraf berdasarkan aktiviti akaun.",
  tnc5: "Semua keputusan pihak pengurusan Pussy888 adalah muktamad.",
  welcomVIPDesc: "Tingkatkan pegalaman bermain game anda. Masukilah Kasino Ruang Vip Premier yang eksklusif",
  welcomVIPDesc0: "Masukilah Kasino Ruang Vip Premier yang eksklusif",
  welcomVIPDesc1: "Nikmati hak istimewa sebagai 3PPBET VIP",
  welcomVIPDesc2:
    "Dengan Program VIP baru kami, anggota setia dapat mendapatkan lebih banyak manfaat saat bermain di 3PPBET VIP Premier. Ini lebih dari sekadar insentif, kami menawarkan promosi eksklusif hanya untuk VIP dan putaran bonus dengan pengembalian terbaik. Selain eksklusivitas, anggota VIP adalah prioritas utama kami. Mulai dari deposit dan penarikan hingga masalah permainan apapun, semua permintaan VIP adalah yang pertama dalam daftar kami.",
  welcomVIPDesc3: "PENGESAHAN IDENTITI DIWAJIBKAN UNTUK NAIK TARAF VIP",
  Welcome: "Selamat Datang",
  NextLevel: "Tahap Seterusnya",
  ByInvitation: "Dengan Semputan Sahaja",
  Current: "Semasa",
  Prev: "Sebelum",
  Next: "Seterusnya",
};
