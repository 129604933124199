export interface RootState {
	isVendorRegistration: boolean;
}



function state(): RootState {
	return {
		isVendorRegistration: false,
	};
}

export default state;
