<template>
  <b-container>
    <b-row>
      <b-container class="my-4">
        <b-container class="h-100">
          <b-row>
            <b-col lg="6" sm="12">
              <div class="d-lg-none">
                <div class="homeAboutTitle">{{ $t("HomeAbout.AboutUs") }}</div>
                <div class="homeAboutContent">
                  {{ $t("HomeAbout.AboutDesc") }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>
<style scoped>
.homeAboutTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  text-align: left;
  text-transform: capitalize;
  color: #ffffff;
}
.homeAboutContent {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  text-transform: capitalize;
}
</style>
