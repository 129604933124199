export default {
    REPORT: "PUSSY888 MASA SEBENAR",
    DEPOSIT: "Deposit Masa Sebenar",
    WITHDRAW: "Pengeluaran Masa Sebenar",
    TOPDEPOSIT: "Deposit Masa Sebenar Tertinggi",
    TOPWITHDRAW: "Pengeluaran Masa Sebenar Tertinggi",
    NO: "No.",
    RM: "RM($)",
    USERNAME: "Name Pengguna",
    DATETIME: "Masa-Tarikh"
  };
  