export default {
  PROFILE: "PROFIL",
  AccountID: "ID Akaun",
  GameID: "ID Permainan",
  DateOfBorth: "Tarikh Lahir",
  FullName: "Nama Penuh",
  Update: "Kemas Kini",
  UpdateSuccess: "Kemas Kini Kejayaan",
  doBirth: "Tarikh Lahir",
  DOBHolderRequired: "*Tarikh Lahir Diperlukan",
	birthdayIsCorrect:
		"Sila pastikan tarikh hari lahir adalah betul! Anda hanya boleh set sekali dan tidak boleh ditukar!",
	Cancel: "BATAL",
	OK: "Ya, saya pasti!",
	AreYouSure: "Adakah anda pasti?",
};
