export default {
    REPORT: "PUSSY888 即时",
    DEPOSIT: "即时存款",
    WITHDRAW: "即时提款",
    TOPDEPOSIT: "最高即时存款",
    TOPWITHDRAW: "最高即时提款",
    NO: "No.",
    RM: "RM($)",
    USERNAME: "用户名",
    DATETIME: "日期-时间"
  };
  