<template>
  <b-col cols="12" class="welcomeSloganImg">
    <b-container class="h-100">
      <b-col class="d-flex align-items-center h-100">
        <b-col cols="12">
          <base-image :imgSrc="welcomeBackground" mode="welcomeSlogan" :isFullUrl="false" />
        </b-col>
      </b-col>
    </b-container>
  </b-col>
</template>
<script>
export default {
  data() {
    return {
      welcomeBackground: "pussy888/assets/images/Home/welcomeBanner1.png",
    };
  }
};
</script>
<style scoped>
.welcomeSlogan {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: auto;
  width: 45vw !important;
}
@media (max-width: 575px) {
    .welcomeSlogan {
        width: 75% !important;
    }
}
</style>
