import { VendorStateInterface } from "./state";
import { VendorResponse } from "@/types/Vendor/Response/VendorResponse";
import { VendorAccountResponse } from "@/types/Vendor/Response/VendorAccountResponse";
import { CategoryResponse } from "@/types/Vendor/Response/CategoryResponse";
import { MutationTree } from "vuex";

export enum VendorMutations {
	SET_VENDOR_INFO = "SET_VENDOR_INFO",
	SET_VENDOR_ACCOUNT = "SET_VENDOR_ACCOUNT",
	SET_CATEGORY_LIST = "SET_CATEGORY_LIST",
}

export const mutations: MutationTree<VendorStateInterface> = {
	[VendorMutations.SET_VENDOR_INFO](state, payload: VendorResponse) {
		state.vendor = payload;
	},
	[VendorMutations.SET_VENDOR_ACCOUNT](state, payload: VendorAccountResponse) {
		state.vendorAccount = payload;
	},
	[VendorMutations.SET_CATEGORY_LIST](state, payload: CategoryResponse[]) {
		state.categoryList = payload;
	},
};
