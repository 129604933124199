<template>
	<div style="position: absolute; top: 1vh; transform: translateX(-50%); left: 50%">
		<b-row class="navContainer d-none d-lg-block h-100 container">
			<div class="navContent">
				<nav>
					<NavBarLink> </NavBarLink>
				</nav>
			</div>
		</b-row>
	</div>
</template>

<style scoped>
nav {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: 20px 0px 14px;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}

.navContainer {
	margin: auto;
	width: fit-content;
	z-index: 999;
}

.navContent {
	width: 30vw;
	max-width: 494px;
	max-height: 105px;
}

@media (max-width: 1199px) {
	/*.navContainer {
    top: 1vh;
    left: 25%;
  }*/
}

@media (max-width: 991px) {
	/*nav {
    padding: 20px 0px 14px;
    max-width: 250px;
  }
  .navContainer {
    top: 1vh;
    left: 27.5vw;
  }
  .navContent {
    padding-left: 5px;
    padding-right: 5px;
  }*/
}

@media (max-width: 767px) {
	/*.navContainer {
    top: 1vh;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    left: 5%;
  }*/

	.navContent {
		width: 100%;
	}

	/*nav {
    padding: 10px 0px 2px;
  }*/
}
</style>

<script>
import NavBarLink from "@/components/content/Header/HeaderNavLink";
export default {
	components: {
		NavBarLink,
	},
	computed: {},
	data() {
		return {};
	},
};
</script>
