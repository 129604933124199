<template>
	<AnnouncementView></AnnouncementView>
	<HomeBanner></HomeBanner>
	<HomeWelcomeSlogan></HomeWelcomeSlogan>
	<HomeSwitchSite></HomeSwitchSite>
	<HomeWelcome></HomeWelcome>

	<HomeWithdrawalChart></HomeWithdrawalChart>
	<!-- <HomeFastPayment></HomeFastPayment>
  <HomeJackpot></HomeJackpot> -->
	<HomeReport></HomeReport>
	<HomePromotion></HomePromotion>
	<!-- <HomeHotSlot></HomeHotSlot> -->
	<HomeDownload></HomeDownload>
	<event-view></event-view>
	<HomeAbout></HomeAbout>
</template>
<style>
.homeWelcomeTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	font-size: 42px;
	line-height: 49px;
	color: #fbb645;
}

.homeTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 49px;
	color: #fbb645;
}

.homeTitleTnP {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 49px;
	color: #ffffff;
}
</style>
<script>
import AnnouncementView from "@/components/content/Announcement/AnnouncementView.vue";
// import HomeFastPayment from "@/components/content/Home/HomeFastPayment.vue";
// import HomeJackpot from "@/components/content/Home/HomeJackpot.vue";
import HomeBanner from "@/components/content/Home/HomeBanner.vue";
// import HomeHotSlot from "@/components/content/Home/HomeHotSlot.vue";
import HomeWelcomeSlogan from "@/components/content/Home/HomeWelcomeSlogan.vue";
import HomeWelcome from "@/components/content/Home/HomeWelcome.vue";
import HomeReport from "@/components/content/Home/HomeReport.vue";
import HomeWithdrawalChart from "@/components/content/Home/HomeWithdrawalChart.vue";
import HomePromotion from "@/components/content/Home/HomePromotion.vue";
import HomeDownload from "@/components/content/Home/HomeDownload.vue";
import HomeAbout from "@/components/content/Home/HomeAbout.vue";
import HomeSwitchSite from "@/components/content/Home/HomeSwitchSite.vue";
import EventView from "@/components/content/Event/EventView.vue";

export default {
	props: ["token", "time"],
	data() {
		return {};
	},
	components: {
		HomeBanner,
		AnnouncementView,
		HomeReport,
		HomeWelcomeSlogan,
		HomeWelcome,
		// HomeFastPayment,
		// HomeJackpot,
		// HomeHotSlot,
		HomeWithdrawalChart,
		HomePromotion,
		HomeDownload,
		HomeAbout,
		HomeSwitchSite,
		EventView,
	},
	methods: {},
};
</script>
