<template>
	<b-row>
		<b-col lg="6" sm="12" class="my-2 p-4">
			<div class="align-item-center">
				<svg
					width="22"
					height="27"
					viewBox="0 0 22 27"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					class="me-1"
				>
					<image
						id="image0_1116_822"
						data-name="android icon.png"
						width="22"
						height="27"
						xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAbCAYAAAB4Kn/lAAAB60lEQVRIidWWzWoUQRSFv+mZJP5CQARFRdBIENy4MEpeQJQgqFvBja/gE7iQgFsRJIhLN0HBgAtd6VJwE1TwB3ExihAUDToaNUcqniY1le62p8nGA0VV3ar79dStqjuFpLJyUdKJivF8zsGisQ7legBMAsPAOLAf2AS8A54AhwABzwsJ//hFVyV1tVYfJb2WdLjMtwp6uwCY6oek04OA5wy4XvKBJUnTkhbcn6wDPhcBprxBqXqSJiQ9tL1bBzxfIwRFOhtzsmQvt3u3m+hC7JMft6PAU2ARmAG+Az+BZZfMBR+x3263gZbrx7ZNAJ8C+CZwHngJnDRoLIL+smPbjssJODP8HnAGmAV6HUODDgBTwHFgb4NQvAL2ub0xfO1bNBhCsNAwxj3gs9uLWbQsvMxWQ3AcojWnYt30f4KHon7H57QpqxN3NkSDrWQzB1E4XZs9f2sAXzLsPnCn4RkOWgKmfaHe5kljl+vhhgko6JYZOyVtyWPSjULzNVrSIHrmue9XYrqSO/t1xJlqB3AqGrobkosT1rhtb4BHwAvgsi/JX1X8NbWTpY7afiWyXSvzr7ogu5P+HtfbIttomXMVeCTph2dAUPxkSOfUAodNjJVnwdU49l+uftV4V3yRdENSZlt4S3xwOVboJ/EHH4DhZeN8q+MAAAAASUVORK5CYII="
					/>
				</svg>
				{{ $t("DownloadQR.Android") }}
			</div>
			<div class="qrContent">
				<qrcode-vue :value="androidDownloadUrl" level="H" class="w-100 h-auto mx-auto d-none d-md-block" />
			</div>
			<base-image
				:imgSrc="androidQrUrl"
				mode="QrImage mx-auto d-block d-md-none"
				:isFullUrl="true"
				fluid-grow
			></base-image>
			<b-button type="download" variant="downloadGame" @click="downloadGame(androidDownloadUrl)">{{
				$t("DownloadQR.Download")
			}}</b-button>
		</b-col>
		<b-col lg="6" sm="12" class="my-2 p-4">
			<div class="align-item-center">
				<svg
					width="23"
					height="27"
					viewBox="0 0 23 27"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					class="me-1"
				>
					<image
						id="image0_1116_826"
						data-name="ios icon.png"
						width="23"
						height="27"
						xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAbCAYAAACX6BTbAAABi0lEQVRIia2WPywEQRTGf3eIhMQJp5CoBOUlEhJaBZfo1SKhuE6lpvCn1IuKWqGgUDqJHC1R6IRKggjiJJ/MmZPZccvtmS/ZYiYzv7fzvjf7FkmNPjlJO5IuJGVqMRoFLyiqplDwvAc+jFubqkSoXyngAehwdowCZ7UI6SRkYMYDL8eBK0qYki2bimdJ83+tj0tLJ5ADWoAr4MbOdwP9QMmOx4FB4Mme4CZC8aKZktqW9OgY9iGpaCukV1JW0pqkO89Ys25f0lCtaumTdOttaFSzhukaWgR6Exocpyk3LRuB3tho0zW0GXix5v1X18BAlWHSMhEIbLToDtL2hoWQqeljH94VCF4GXn34WyC48a7Vh98FgqftrY5MXAaCG21ERpLaJb0HrPOCf/1LAeFGS5I6m+0BdoGRgOlZBzLVT26LrZqkzeM3jVVhZRstlA6AU7dZmP74DLQFCJAF7t00mCjTAcBzBvxF/Nn7Vv5RJXv19NBVoOAYbH4njoBz688wMAn0OHtOgPz3CPgEqZNbJXsgFG4AAAAASUVORK5CYII="
					/>
				</svg>
				{{ $t("DownloadQR.IOS") }}
			</div>
			<div class="qrContent">
				<qrcode-vue :value="iosDownloadUrl" level="H" class="w-100 h-auto mx-auto d-none d-md-block" />
			</div>
			<base-image :imgSrc="iosQrUrl" mode="QrImage mx-auto d-block d-md-none" :isFullUrl="true" fluid-grow></base-image>
			<b-button type="download" variant="downloadGame" @click="downloadGame(iosDownloadUrl)">{{
				$t("DownloadQR.Download")
			}}</b-button>
		</b-col>
	</b-row>
</template>

<style scoped>
.qrContent {
	width: 100px;
	height: 100px;
	margin: 15px auto;
	background: #fff;
	border: 5px solid white;
}

.btn-downloadGame {
	height: 30px;
	width: 50%;
	align-items: center;
	display: inline-flex;
	background: linear-gradient(180deg, #e13222 0%, #73150c 100%);
	border: 1px solid #fcff3c !important;
	border-radius: 30px !important;
	white-space: nowrap;
	justify-content: center;
}

.btn-downloadGame:hover,
.btn-downloadGame:active {
	background: linear-gradient(180deg, #e13222 0%, #73150c 100%) !important;
	border: 1px solid #fcff3c !important;
}

@media (max-width: 1399px) {
	.btn-downloadGame {
		width: 75% !important;
	}
}
@media (max-width: 767px) {
	.btn-downloadGame {
		width: 100% !important;
	}
}
.QrImage {
	width: 25% !important;
	height: auto;
	margin: 1rem 0;
}
.icon {
	width: 10px !important;
}
</style>
<script>
import QrcodeVue from "qrcode.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";
export default {
	data() {
		return {
			androidIcon: require("@/assets/android icon 1.png"),
			androidQrUrl: require("@/assets/android.png"),
			iosIcon: require("@/assets/ios icon 1.png"),
			iosQrUrl: require("@/assets/ios.png"),
			iosDownloadUrl: "",
			androidDownloadUrl: "",
		};
	},
	methods: {
		downloadGame(downloadUrl) {
			window.open(downloadUrl, "_blank");
		},
	},
	components: {
		QrcodeVue,
		BaseImage,
	},
	async created() {
		await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { vendorId: 30, downloadType: "ANDROID" });
		await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { vendorId: 30, downloadType: "IOS" });
		this.androidDownloadUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("ANDROID").downloadUrl;
		this.iosDownloadUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("IOS").downloadUrl;
	},
};
</script>
