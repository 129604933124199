export default {
    REPORT: "PUSSY888 REAL TIME",
    DEPOSIT: "Realtime Deposit",
    WITHDRAW: "Realtime Withdraw",
    TOPDEPOSIT: "Realtime Top Deposit",
    TOPWITHDRAW: "Realtime Top Withdraw",
    NO: "No.",
    RM: "RM($)",
    USERNAME: "Username",
    DATETIME: "Date-Time"
  };
  