<template>
	<b-container class="mt-5">
		<b-row align-h="between">
			<b-col lg="12" sm="12" class="homeTitleTnP d-flex justify-content-center">{{ $t("HomeReport.REPORT") }}</b-col>
		</b-row>
		<br />

		<b-row>
			<b-tabs
				justified
				style="padding: 12px"
				:active-nav-item-class="'tab-active-class'"
				:title-item-class="'tab-class'"
			>
				<b-tab v-for="rT in reportType" :key="rT">
					<template #title>
						<div class="homeReportTabTitle">
							{{ $t("HomeReport." + rT.Text) }}
						</div>
					</template>
					<table :fields="realTimeLabel" class="table realTimeTable">
						<thead>
							<tr>
								<th v-for="(rTLabel, index) in realTimeLabel" :key="`rankLabel-${index}`" class="">
									{{ $t("HomeReport." + rTLabel) }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="realTimeItem in rT.realTimeList" :key="realTimeItem">
								<td>{{ realTimeItem.rank }}</td>
								<td data-content="$">{{ realTimeItem.username }}</td>
								<td data-content="$">{{ realTimeItem.rm }}</td>
							</tr>
						</tbody>
					</table>
				</b-tab>
			</b-tabs>
		</b-row>
	</b-container>
</template>
<style>
.nav-link {
	border-radius: unset !important;
}
</style>
<style scoped>
.nav-link.active {
	background: #5723a0 !important;
}
.tab-class {
	background: #5723a0 !important;
	color: white !important;
}
.realTimeTable > tbody tr:nth-last-child(-n + 1) td:first-child {
	border-bottom-left-radius: 16.5px;
	position: relative;
}
.realTimeTable > tbody tr:nth-last-child(-n + 1) td:last-child {
	border-bottom-right-radius: 16.5px;
}
.realTimeTable > tbody {
	border: unset;
	color: black;
	background: #e8e4f9;
}
.realTimeTable > thead {
	color: #f8eb4c;
	background: #32164d;
}
.realTimeTable > thead > tr > th:nth-child(n + 2) {
	border-inline: 2px solid #5723a0;
}
.realTimeTable > thead > tr > th:nth-last-child(1) {
	border: unset;
}
.realTimeTable > tbody > tr > td[data-content="$"] {
	border-inline: 2px solid #5723a0;
}

@media (max-width: 768px) {
	.homeReportTabTitle,
	.realTimeTable {
		font-size: 0.75rem;
	}
}
</style>
<script>
export default {
	data() {
		return {
			reportType: [
				{
					Text: "DEPOSIT",
					index: 0,
					realTimeList: [
						{
							id: 1,
							rank: "1",
							username: "******123",
							rm: "100",
						},
						{
							id: 2,
							rank: "2",
							username: "******312",
							rm: "200",
						},
						{
							id: 3,
							rank: "3",
							username: "******231",
							rm: "300",
						},
						{
							id: 4,
							rank: "4",
							username: "******321",
							rm: "400",
						},
						{
							id: 5,
							rank: "5",
							username: "******132",
							rm: "500",
						},
					],
				},
				{
					Text: "WITHDRAW",
					index: 1,
					realTimeList: [
						{
							id: 1,
							rank: "1",
							username: "******960",
							rm: "100",
						},
						{
							id: 2,
							rank: "2",
							username: "******182",
							rm: "200",
						},
						{
							id: 3,
							rank: "3",
							username: "******893",
							rm: "300",
						},
						{
							id: 4,
							rank: "4",
							username: "******596",
							rm: "400",
						},
						{
							id: 5,
							rank: "5",
							username: "******627",
							rm: "500",
						},
					],
				},
				{
					Text: "TOPDEPOSIT",
					index: 2,
					realTimeList: [
						{
							id: 1,
							rank: "1",
							username: "******210",
							rm: "1000",
						},
						{
							id: 2,
							rank: "2",
							username: "******658",
							rm: "1000",
						},
						{
							id: 3,
							rank: "3",
							username: "******827",
							rm: "800",
						},
						{
							id: 4,
							rank: "4",
							username: "******962",
							rm: "500",
						},
						{
							id: 5,
							rank: "5",
							username: "******132",
							rm: "500",
						},
					],
				},
				{
					Text: "TOPWITHDRAW",
					index: 3,
					realTimeList: [
						{
							id: 1,
							rank: "1",
							username: "******960",
							rm: "900",
						},
						{
							id: 2,
							rank: "2",
							username: "******329",
							rm: "850",
						},
						{
							id: 3,
							rank: "3",
							username: "******749",
							rm: "700",
						},
						{
							id: 4,
							rank: "4",
							username: "******942",
							rm: "600",
						},
						{
							id: 5,
							rank: "5",
							username: "******035",
							rm: "500",
						},
					],
				},
			],
			realTimeLabel: ["NO", "USERNAME", "RM"],
			depositWithdrawalAmount: [50, 60, 80, 90, 100, 120, 150, 180, 200, 250, 300, 350, 420, 450, 500],
		};
	},
	created() {
		const depositWithdrawaLength = this.depositWithdrawalAmount.length;
		const depositType = this.reportType.filter((x) => x.Text == "DEPOSIT")[0];
		depositType.realTimeList.forEach((depositItem) => {
			const amount = this.depositWithdrawalAmount[Math.floor(Math.random() * depositWithdrawaLength)];
			const randomUsername = Math.floor(Math.random() * 899) + 100;
			depositItem.username = "*******" + randomUsername.toString();
			depositItem.rm = amount;
			return depositItem;
		});

		const withdrawalType = this.reportType.filter((x) => x.Text == "WITHDRAW")[0];
		withdrawalType.realTimeList.forEach((withdrawalItem) => {
			const amount = this.depositWithdrawalAmount[Math.floor(Math.random() * depositWithdrawaLength)];
			const randomUsername = Math.floor(Math.random() * 899) + 100;
			withdrawalItem.username = "*******" + randomUsername.toString();
			withdrawalItem.rm = amount;
			return withdrawalItem;
		});
	},
};
</script>
