<template>
	<b-container>
		<b-row>
			<b-container class="h-100">
				<b-row class="d-flex align-items-center h-100">
					<b-col lg="6" sm="12">
						<div style="padding: 50px 0px">
							<b-row align-h="between" class="d-none d-sm-block">
								<b-col lg="12" sm="12" class="homeWelcomeTitle d-flex justify-content-start">{{
									$t("HomeWelcome.Welcome")
								}}</b-col>
							</b-row>
							<br />
							<p style="text-align: left">
								{{ $t("HomeWelcome.HomeWelcomeDesc") }}
							</p>
							<br />
							<b-row style="padding-left: 12px">
								<template v-if="!isLogin">
									<b-button variant="main-gradient" v-b-modal="'signup-modal'" style="width: fit-content">
										{{ $t("HomeWelcome.Join") }}
									</b-button>
								</template>
								<template v-else>
									<b-button
										variant="main-gradient"										
										style="width: fit-content"
										v-if="isMaintenance"
										@click="maintenancepopup"
										:class="{ disabled: !checkIsLoadMemberBank }"
									>
										{{ $t("HomeWelcome.Deposit") }}
									</b-button>
									<b-button
										variant="main-gradient"
										v-b-modal="'deposit-modal'"
										style="width: fit-content"
										v-else-if="this.checkIsMemberBankExist === true"
										:class="{ disabled: !checkIsLoadMemberBank }"
									>
										{{ $t("HomeWelcome.Deposit") }}
									</b-button>
									<b-button
										variant="main-gradient"
										v-b-modal="'bankInfo-modal'"
										style="width: fit-content"
										:class="{ disabled: !checkIsLoadMemberBank }"
										v-else
									>
										{{ $t("HomeWelcome.Deposit") }}
									</b-button>
								</template>
							</b-row>
						</div>
					</b-col>
					<b-col lg="6" sm="12" class="ml-4 d-none d-lg-block">
						<div style="padding: 80px 0px">
							<base-image :imgSrc="welcomeImage" mode="welcomeSlogan" :isFullUrl="false" />
						</div>
					</b-col>
				</b-row>
			</b-container>
		</b-row>
	</b-container>
</template>
<script>
export default {
	data() {
		return {
			welcomeImage: "pussy888/assets/images/Home/169221.png",
		};
	},
	methods: {
		async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HeaderLogin.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		},
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		checkIsMemberBankExist() {
			return this.$store.getters["member/getIsMemberBankExist"];
		},
		checkIsLoadMemberBank() {
			return this.$store.getters["member/getOnLoadMemberBankStatus"];
		},
		isMaintenance(){
			return this.$store.getters["vendors/getVendor"].maintenance;		
		}
	},
};
</script>
<style scoped>
.welcomeSlogan {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
</style>
