<template>
	<b-container>
		<b-row class="homeDownload" style="background: #160043">
			<b-container class="mt-5">
				<b-container class="h-100">
					<b-row>
						<b-col lg="6" sm="12">
							<div class="d-lg-none">
								<div class="homeDownloadTitle">{{ $t("HomeDownload.DOWNLOADNOW") }}</div>
								<div class="homeDownloadDesc1">{{ $t("HomeDownload.Description1") }}</div>
								<br /><br />
								<template v-if="!isLogin">
									<router-link :to="'/download'">
										<b-button class="" variant="main-gradient" style="width: fit-content">
											{{ $t("HomeDownload.DOWNLOADNOW") }}
										</b-button>
									</router-link>
								</template>
							</div>
							<base-image :imgSrc="downloadBackground" mode="downloadBg" :isFullUrl="false" />
						</b-col>
						<b-col lg="6" sm="12" style="padding: 20px" class="d-none d-lg-block">
							<div style="margin: auto; width: 70%">
								<div class="homeDownloadTitle">{{ $t("HomeDownload.DOWNLOADNOW") }}</div>
								<div class="homeDownloadDesc1">{{ $t("HomeDownload.Description1") }}</div>
								<div class="homeDownloadDesc2">{{ $t("HomeDownload.Description2") }}</div>
								<DownloadQR></DownloadQR>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</b-container>
		</b-row>
	</b-container>
</template>
<script>
import DownloadQR from "@/components/content/Download/DownloadQRHome.vue";
export default {
	data() {
		return {
			downloadBackground: "pussy888/assets/images/Home/god1.png",
		};
	},
	components: {
		DownloadQR,
	},
};
</script>
<style scoped>
.homeDownload {
	background: #160043;
}
@media (max-width: 991px) {
	.homeDownload {
		background: #2e0d0a !important;
	}
}
.downloadBg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 30px;
}
.homeDownloadTitle {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;
	text-align: center;
	text-transform: capitalize;
	color: #ffffff;
}
.homeDownloadDesc1 {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 33px;
	text-align: center;
	text-transform: capitalize;
	color: #e13222;
}
.homeDownloadDesc2 {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	text-transform: capitalize;
	color: #ffffff;
}
</style>
